<template>
  <div class="newsPage">
    <!-- Start  Breadcrumb -->
    <div class="breadcrumb">
      <div class="container">
        <h1>كل اللاعبين</h1>
      </div>
    </div>
    <!-- End  Breadcrumb -->
    <div class="newsSection">
      <div class="container">
        <!-- News -->
        <div class="title-sections">
          <h4>
            كل
            <span>اللاعبين</span>
          </h4>
        </div>
        <!-- News Section -->
        <div class="row">
          <!-- Single -->
          <div
            class="col-md-4 mb-4"
            data-aos="zoom-in"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <div class="single-entrants">
              <div class="img-entrants">
                <img src="../../../../assets/images/entrants/ent1.png" alt="" />
              </div>
              <div class="text-entrants">
                <h5>علي معلول</h5>
                <h6>الأهلي المصري</h6>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 mb-4"
            data-aos="zoom-in"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <div class="single-entrants">
              <div class="img-entrants">
                <img src="../../../../assets/images/entrants/ent2.png" alt="" />
              </div>
              <div class="text-entrants">
                <h5>علي معلول</h5>
                <h6>الأهلي المصري</h6>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 mb-4"
            data-aos="zoom-in"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <div class="single-entrants">
              <div class="img-entrants">
                <img src="../../../../assets/images/entrants/ent3.png" alt="" />
              </div>
              <div class="text-entrants">
                <h5>علي معلول</h5>
                <h6>الأهلي المصري</h6>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 mb-4"
            data-aos="zoom-in"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-once="true"
          >
            <div class="single-entrants">
              <div class="img-entrants">
                <img src="../../../../assets/images/entrants/ent4.png" alt="" />
              </div>
              <div class="text-entrants">
                <h5>علي معلول</h5>
                <h6>الأهلي المصري</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "allNews",
  components: {},
};
</script>
